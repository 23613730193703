<template>
    <div
        class="relative flex w-full flex-1 flex-col items-start justify-between self-stretch"
    >
        <business-snapshot-loading :loading="loading" type="profit-and-loss" />

        <business-snapshot-data-overview
            v-if="fromDate && toDate"
            :title="$t('businessSnapshot.profitability.profitAndLoss.title')"
            :from-date="fromDate"
            :to-date="toDate"
            :current-value="current"
            :previous-value="previous"
            :percentage="percentage"
            variant="primary"
        />

        <div>
            <stackbar-chart
                v-if="!isBothZero"
                :height="300"
                :chart-data="chartData"
                :chart-options="chartOptions"
                :plugins="chartPlugins"
            />
            <stackbar-chart
                v-else
                :height="300"
                :chart-data="chartDataEmpty"
                :chart-options="chartOptions"
                :plugins="chartPlugins"
            />
        </div>

        <div class="text-xs font-normal text-gray-700">
            <p>
                {{ chartDescription }}
            </p>
        </div>
    </div>
</template>
<script setup>
import { useBusinessSnapshot } from '@tenant/modules/tenant/business-snapshot/composables/use-business-snapshot'

const {
    executeSnapshot,
    getTextPreviousPeriod,
    getTextCurrentPeriod,
    convertToKMB,
    loading,
} = useBusinessSnapshot()
const { t } = useI18n()

const props = defineProps({
    fromDate: {
        type: String,
        required: true,
    },

    toDate: {
        type: String,
        required: true,
    },
})

const current = ref(null)
const previous = ref(null)
const percentage = ref(null)

onMounted(async () => {
    await fetchChartData()
})

const fetchChartData = async () => {
    const data = await executeSnapshot(
        'profit-and-loss',
        props.fromDate,
        props.toDate
    )

    if (!data) return

    current.value = data.current
    previous.value = data.previous
    percentage.value = data.percentage_change
}

watch(props, async () => {
    await fetchChartData()
})

const currentPeriod = computed(() => {
    return getTextCurrentPeriod(props.fromDate, props.toDate)
})

const previousPeriod = computed(() => {
    return getTextPreviousPeriod(props.fromDate, props.toDate)
})

const chartDescription = computed(() => {
    if (current.value && current.value > 0) {
        return t('businessSnapshot.profitability.profitAndLoss.madeProfit')
    }

    return t('businessSnapshot.profitability.profitAndLoss.noProfit')
})

const isBothZero = computed(() => {
    return !current.value && !previous.value
})

const chartData = computed(() => {
    return {
        labels: [
            [currentPeriod.value, ''],
            [previousPeriod.value, ''],
        ],
        datasets: [
            {
                data: [current.value, previous.value],
                backgroundColor: [
                    'rgba(187, 243, 253, 0.5)',
                    'rgba(242, 244, 247, 1)',
                ],
                borderColor: ['rgba(60, 220, 250, 1)', '#80858F'],
                borderWidth: [1, 0],
                borderRadius: 4,
                borderDashed: [false, !!previous.value],
            },
        ],
    }
})

const chartDataEmpty = computed(() => {
    return {
        labels: [
            [currentPeriod.value, ''],
            [previousPeriod.value, ''],
        ],
        datasets: [
            {
                data: [-100, 100],
                backgroundColor: ['transparent', 'transparent'],
                borderColor: ['transparent', 'transparent'],
            },
        ],
    }
})

const chartOptions = computed(() => {
    return {
        animation: false,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
            y: {
                border: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
                grid: {
                    display: true,
                    color: function (context) {
                        if (context.tick.value > 0) {
                            return 'transparent'
                        } else if (context.tick.value < 0) {
                            return 'transparent'
                        }

                        return '#59606D'
                    },
                },
            },
        },
    }
})

const chartPlugins = computed(() => {
    return [
        {
            id: 'customDataLabels',
            afterDatasetDraw(chart) {
                const {
                    ctx,
                    data,
                    chartArea: { bottom },
                    scales: { x },
                } = chart

                ctx.save()

                const datasetArray = data.datasets[0].data
                datasetArray.forEach((value, index) => {
                    ctx.font = 'bold 12px sans-serif'
                    ctx.textAlign = 'center'
                    ctx.fillStyle = '#59606D'
                    ctx.fillText(
                        !isBothZero.value ? convertToKMB(value) : 0,
                        x.getPixelForValue(index),
                        bottom + 37
                    )
                })
            },
        },
    ]
})
</script>
