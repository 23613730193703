<template>
    <base-sticky-heading class="min-h-[82px] items-center">
        <template #title>
            <div class="flex flex-col gap-1">
                <div
                    class="align-items-center flex items-center gap-x-2.5 gap-y-1"
                >
                    <div class="text-lg font-medium text-gray-900">
                        {{ $t('businessSnapshot.title') }}
                    </div>
                    <div class="text-sm font-normal text-gray-600">
                        {{ description }}
                    </div>
                </div>
            </div>
        </template>

        <template #right>
            <div class="flex flex-col gap-x-3 gap-y-1 xl:flex-row">
                <base-button
                    variant="default"
                    icon="line-icons:media-&-devices:printer"
                    size="sm"
                />

                <form-single-select
                    class="min-w-[400px]"
                    v-model="range"
                    :options="ranges"
                    :disabled="isDisabled"
                    @change="setAllLoading"
                >
                    <template #option-label="{ item }">
                        <div
                            class="flex w-full flex-row items-center justify-between pr-1"
                        >
                            <span class="mb-2"> {{ item.label }} </span>
                            <span class="text-sm text-gray-500">
                                {{ setRangeDateDescription(item.value) }}
                            </span>
                        </div>
                    </template>
                </form-single-select>
            </div>
        </template>

        <div class="flex flex-col">
            <business-snapshot-profitability
                v-if="fromDate && toDate"
                :from-date="fromDate"
                :to-date="toDate"
            />
            <business-snapshot-efficiency
                v-if="fromDate && toDate"
                :from-date="fromDate"
                :to-date="toDate"
            />
            <business-snapshot-financial-cash
                v-if="fromDate && toDate"
                :from-date="fromDate"
                :to-date="toDate"
            />
            <business-snapshot-financial-ratios
                v-if="fromDate && toDate"
                :from-date="fromDate"
                :to-date="toDate"
            />
        </div>
    </base-sticky-heading>
</template>
<script setup>
import { RANGE_OPTIONS } from '@tenant/modules/tenant/business-snapshot/utils/constants.utils'
import { dateCustomFormat } from '@tenant/core/filter'

const { t } = useI18n()
const range = ref(RANGE_OPTIONS.LAST_MONTH)
const currentDate = new Date()
const fromDate = ref('')
const toDate = ref('')
const loading = ref({
    'profit-and-loss': false,
    income: false,
    expense: false,
    'net-profit-margin': false,
    'gross-profit-margin': false,
    'largest-operation-expense': false,
    'balance-sheet': false,
    average: false,
    'financial-ratios': false,
})
const emitter = useEmitter()

useHead({
    title: t('businessSnapshot.title'),
})

const ranges = ref([
    {
        label: t('businessSnapshot.range.lastMonth'),
        value: RANGE_OPTIONS.LAST_MONTH,
    },
    {
        label: t('businessSnapshot.range.lastQuarter'),
        value: RANGE_OPTIONS.LAST_QUARTER,
    },
    {
        label: t('businessSnapshot.range.lastFinancialYear'),
        value: RANGE_OPTIONS.LAST_FINANCIAL_YEAR,
    },
    {
        label: t('businessSnapshot.range.yearToDate'),
        value: RANGE_OPTIONS.YEAR_TO_DATE,
    },
])

const getStartDateAndEndDate = (value) => {
    switch (value) {
        case RANGE_OPTIONS.LAST_MONTH:
            return {
                startDate: new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth() - 1,
                    1
                ),
                endDate: new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    0
                ),
            }
        case RANGE_OPTIONS.LAST_QUARTER:
            return {
                startDate: new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth() - 3,
                    1
                ),
                endDate: new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    0
                ),
            }
        case RANGE_OPTIONS.LAST_FINANCIAL_YEAR:
            return {
                startDate: new Date(
                    currentDate.getFullYear() - 2,
                    currentDate.getMonth() + 1,
                    1
                ),
                endDate: new Date(
                    currentDate.getFullYear() - 1,
                    currentDate.getMonth() + 1,
                    0
                ),
            }
        case RANGE_OPTIONS.YEAR_TO_DATE:
            return {
                startDate: new Date(
                    currentDate.getFullYear() - 1,
                    currentDate.getMonth() + 1,
                    1
                ),
                endDate: new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate()
                ),
            }
    }
}

watch(
    range,
    (value) => {
        const { startDate, endDate } = getStartDateAndEndDate(value)

        fromDate.value = dateCustomFormat(startDate, 'YYYY-MM-DD')
        toDate.value = dateCustomFormat(endDate, 'YYYY-MM-DD')
    },
    { immediate: true }
)

const description = computed(() => {
    const { startDate, endDate } = getStartDateAndEndDate(range.value)

    switch (range.value) {
        case RANGE_OPTIONS.LAST_MONTH:
            return t('businessSnapshot.description.lastMonth', {
                date: dateCustomFormat(endDate, 'MMM YYYY'),
            })
        case RANGE_OPTIONS.LAST_QUARTER:
            return t('businessSnapshot.description.lastQuarter', {
                startDate: dateCustomFormat(startDate, 'D MMM'),
                endDate: dateCustomFormat(endDate, 'D MMM YYYY'),
            })
        case RANGE_OPTIONS.LAST_FINANCIAL_YEAR:
            return t('businessSnapshot.description.lastFinancialYear', {
                startDate: dateCustomFormat(startDate, 'D MMM YYYY'),
                endDate: dateCustomFormat(endDate, 'D MMM YYYY'),
            })
        case RANGE_OPTIONS.YEAR_TO_DATE:
            return t('businessSnapshot.description.yearToDate', {
                startDate: dateCustomFormat(startDate, 'D MMM YYYY'),
                endDate: dateCustomFormat(endDate, 'D MMM YYYY'),
            })
    }

    return ''
})

const setRangeDateDescription = (date) => {
    switch (date) {
        case RANGE_OPTIONS.LAST_MONTH: {
            const { endDate } = getStartDateAndEndDate(date)
            return `${dateCustomFormat(endDate, 'MMM YYYY')}`
        }

        case RANGE_OPTIONS.LAST_QUARTER: {
            const { startDate, endDate } = getStartDateAndEndDate(date)
            return `${dateCustomFormat(
                startDate,
                'D MMM'
            )} - ${dateCustomFormat(endDate, 'D MMM YYYY')}`
        }
        default: {
            const { startDate, endDate } = getStartDateAndEndDate(date)

            return `${dateCustomFormat(
                startDate,
                'D MMM YYYY'
            )} - ${dateCustomFormat(endDate, 'D MMM YYYY')}`
        }
    }
}

const setAllLoading = () => {
    Object.keys(loading.value).forEach((i) => (loading.value[i] = true))
}

onMounted(() => {
    emitter.on('bs-loaded', (type) => {
        loading.value[type] = false
    })
})

const isDisabled = computed(() =>
    Object.values(loading.value).some((value) => value === true)
)
</script>
