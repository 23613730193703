<template>
    <div class="flex w-full flex-col items-start gap-y-2.5 self-stretch p-6">
        <span class="text-sm font-semibold text-gray-900">
            {{ $t('businessSnapshot.financialRatios.title') }}
        </span>

        <div v-if="chartData" class="grid w-full grid-cols-3 gap-6">
            <business-snapshot-financial-ratios-general-card
                v-for="type in types"
                :key="type"
                :from-date="fromDate"
                :to-date="toDate"
                :loading="loading"
                :type="type"
                :data="chartData[type]"
                :step="chartData.step"
            />
        </div>
    </div>
</template>
<script setup>
import { useBusinessSnapshot } from '@tenant/modules/tenant/business-snapshot/composables/use-business-snapshot'

const { executeSnapshot, loading, result: chartData } = useBusinessSnapshot()

const props = defineProps({
    fromDate: {
        type: String,
        required: true,
    },

    toDate: {
        type: String,
        required: true,
    },
})

const types = ref([
    'current',
    'debt',
    'currentLiabilitiesToNetWorth',
    'debitToEquity',
    'workingCapitalToTotalAssets',
    'fixedAssetsToNetWorth',
])

onMounted(() => {
    fetchSnapshot()
})

watch(props, () => {
    fetchSnapshot()
})

const fetchSnapshot = () => {
    return executeSnapshot('financial-ratios', props.fromDate, props.toDate)
}
</script>
