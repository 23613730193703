<template>
    <div class="relative flex w-full flex-1 flex-col">
        <business-snapshot-loading
            :loading="loading"
            type="largest-operation-expense"
        />

        <div
            class="flex w-full flex-row rounded-tl-md rounded-tr-md bg-gray-25"
            :class="{
                'border-b border-b-gray-200': rows.length > 0,
                'rounded-bl-md rounded-br-md': rows.length === 0,
            }"
        >
            <div
                class="flex w-1/3 items-center self-stretch px-6 py-3 text-left text-xs text-gray-500"
            >
                {{
                    $t(
                        'businessSnapshot.efficiency.largestOperatingExpense.title'
                    )
                }}
            </div>
            <div
                class="flex w-1/3 items-center self-stretch px-6 py-3 text-left text-xs text-gray-500"
            >
                {{ getTextCurrentPeriod(props.fromDate, props.toDate) }}
            </div>
            <div
                class="flex w-1/3 items-center self-stretch px-6 py-3 text-left text-xs text-gray-500"
            >
                {{ getTextPreviousPeriod(props.fromDate, props.toDate) }}
            </div>
        </div>

        <div
            v-for="(row, index) in rows"
            :key="index"
            class="flex w-full flex-row border-b-gray-200"
            :class="{
                'border-b': index !== rows.length - 1,
            }"
        >
            <div
                class="flex w-1/3 items-center self-stretch px-6 py-4 text-left text-sm text-gray-500"
            >
                {{ row.chart_of_account_name }}
            </div>
            <div
                class="flex w-1/3 items-center self-stretch px-6 py-4 text-left text-sm text-gray-500"
            >
                <business-snapshot-efficiency-largest-operating-expense-cell-amount
                    :amount="row.current.amount"
                    :percentage="row.current.percentage"
                    :previous-amount="row.previous.amount"
                />
            </div>
            <div
                class="flex w-1/3 items-center self-stretch px-6 py-4 text-left text-sm text-gray-500"
            >
                <business-snapshot-efficiency-largest-operating-expense-cell-amount
                    :amount="row.previous.amount"
                    :percentage="row.previous.percentage"
                    :previous-amount="row.previous_of_previous.amount"
                />
            </div>
        </div>
    </div>
</template>
<script setup>
import { useBusinessSnapshot } from '@tenant/modules/tenant/business-snapshot/composables/use-business-snapshot'

const {
    executeSnapshot,
    getTextCurrentPeriod,
    getTextPreviousPeriod,
    loading,
} = useBusinessSnapshot()

const props = defineProps({
    fromDate: {
        type: String,
        required: true,
    },

    toDate: {
        type: String,
        required: true,
    },
})

const rows = ref([])

onMounted(async () => {
    await fetchChartData()
})

const fetchChartData = async () => {
    const data = await executeSnapshot(
        'largest-operation-expense',
        props.fromDate,
        props.toDate
    )

    if (!data) return

    rows.value = data.data
}

watch(props, async () => {
    await fetchChartData()
})
</script>
