<template>
    <div
        class="relative flex w-full flex-1 flex-row items-start justify-between self-stretch"
    >
        <business-snapshot-loading :loading="loading" type="expense" />

        <div
            class="flex w-1/3 max-w-[400px] flex-col items-start justify-between self-stretch"
        >
            <business-snapshot-data-overview
                v-if="fromDate && toDate"
                :title="$t('businessSnapshot.profitability.expenses.title')"
                :from-date="fromDate"
                :to-date="toDate"
                :current-value="current"
                :previous-value="previous"
                :percentage="percentage"
                variant="primary"
            />

            <div
                class="flex flex-col items-start gap-1.5 text-sm text-gray-700"
            >
                <div>
                    <span>{{
                        $t(
                            'businessSnapshot.profitability.expenses.costOfGoodsSold'
                        )
                    }}</span
                    >:
                    <span class="font-bold">{{
                        categoriesAmounts['cogs']
                            ? $filters.numberFormat(
                                  categoriesAmounts['cogs'],
                                  2
                              )
                            : 0.0
                    }}</span>
                </div>
                <div>
                    <span>{{
                        $t(
                            'businessSnapshot.profitability.expenses.operatingExpenses'
                        )
                    }}</span
                    >:
                    <span class="font-bold">{{
                        categoriesAmounts['expense']
                            ? $filters.numberFormat(
                                  categoriesAmounts['expense'],
                                  2
                              )
                            : 0.0
                    }}</span>
                </div>
            </div>
        </div>

        <div class="relative h-full max-h-full max-w-full flex-1 pl-6">
            <line-chart
                v-if="!isZero"
                ref="line"
                height="100%"
                :chartData="chartData"
                :chartOptions="chartOptions"
                :plugins="chartPlugins"
            />

            <line-chart
                v-else
                ref="line"
                height="100%"
                :chartData="chartDataEmpty"
                :chartOptions="chartOptionsEmpty"
                :plugins="chartPlugins"
            />
        </div>
    </div>
</template>
<script setup>
import { useBusinessSnapshot } from '@tenant/modules/tenant/business-snapshot/composables/use-business-snapshot'
import { customDateFormat } from '@tenant/utils/day'
import dayjs from 'dayjs'
import { numberFormat } from '@tenant/utils/helper'
import { externalTooltipHandler } from '@tenant/components/chart/plugins/tooltip'

const { executeSnapshot, convertToKMB, drawVerticalLineWhenHover, loading } =
    useBusinessSnapshot()

const props = defineProps({
    fromDate: {
        type: String,
        required: true,
    },

    toDate: {
        type: String,
        required: true,
    },
})

const current = ref(null)
const previous = ref(null)
const percentage = ref(null)
const amounts = ref([])
const previousAmounts = ref([])
const dates = ref([])
const step = ref('')
const categoriesAmounts = ref({})

onMounted(async () => {
    await fetchChartData()
})

const fetchChartData = async () => {
    const data = await executeSnapshot('expense', props.fromDate, props.toDate)

    if (!data) return

    current.value = data.current
    previous.value = data.previous
    percentage.value = data.percentage_change
    amounts.value = data.data[0]
    previousAmounts.value = data.data[1]
    dates.value = data.labels
    step.value = data?.additional?.step
    categoriesAmounts.value = data?.additional?.categorize_amount
}

const isZero = computed(() => {
    return (
        amounts.value.every((i) => i === 0) &&
        previousAmounts.value.every((i) => i === 0)
    )
})

watch(props, async () => {
    await fetchChartData()
})

const chartDataEmpty = computed(() => ({
    labels: dates.value,
    datasets: [
        {
            data: Array.from({ length: dates.value.length }, (_, i) =>
                i === 0 ? -100 : 100
            ),
            backgroundColor: 'transparent',
            borderColor: 'transparent',
        },
    ],
}))

const chartOptionsEmpty = computed(() => ({
    ...chartOptionsBase,
    scales: {
        x: getXScale(true),
        y: getYScale(true),
    },
}))

const chartOptions = computed(() => ({
    ...chartOptionsBase,
    interaction: { mode: 'index', intersect: false },
    scales: {
        x: getXScale(false),
        y: getYScale(false),
    },
}))

function getXScale(empty) {
    if (empty) {
        return {
            ticks: {
                autoSkip: false,
                source: 'data',
                color: '#667085',
                callback: function (val, index) {
                    const label = dates.value[index]
                    if (step.value === '1 month') {
                        if (dates.value.length < 12) {
                            return dayjs(label).format('MMM')
                        }

                        return index % 2 === 0 ? dayjs(label).format('MMM') : ''
                    }

                    if (index === 0) {
                        return dayjs(label).format('DD MMM')
                    }
                    if (index === dates.value.length - 1) {
                        return dayjs(label).format('DD MMM')
                    }
                    if (index === Math.floor(dates.value.length / 2)) {
                        return dayjs(label).format('DD MMM')
                    }

                    return null
                },
            },
            grid: {
                drawBorder: false,
                display: false,
            },
        }
    }

    return {
        ticks: {
            autoSkip: false,
            source: 'data',
            callback: function (val, index) {
                const label = dates.value[index]
                if (step.value === '1 month') {
                    if (dates.value.length < 12) {
                        return dayjs(label).format('MMM')
                    }

                    return index % 2 === 0 ? dayjs(label).format('MMM') : ''
                }

                return !amounts.value[index] && !previousAmounts.value[index]
                    ? ''
                    : dayjs(label).format('DD MMM')
            },
            color: '#667085',
        },
        grid: {
            drawBorder: false,
            display: false,
        },
    }
}

function getYScale(empty) {
    if (empty) {
        return {
            border: {
                display: false,
            },
            ticks: {
                display: true,
                color: function (context) {
                    return context.tick.value !== 0 ? 'transparent' : '#666666'
                },
                count: 3,
            },
            max: 100,
            grid: {
                display: true,
                color: function (context) {
                    if (context.tick.value > 0) {
                        return 'transparent'
                    } else if (context.tick.value < 0) {
                        return 'transparent'
                    }

                    return '#FA8200'
                },
            },
        }
    }

    return {
        beginAtZero: true,
        border: { display: false },
        ticks: {
            color: '#667085',
            display: true,
            count: 3,
            callback: (value) => convertToKMB(value),
        },
        max: ({ chart }) =>
            Math.max(...chart.data.datasets.flatMap((dataset) => dataset.data)),
        min: ({ chart }) =>
            Math.min(...chart.data.datasets.flatMap((dataset) => dataset.data)),
        grid: { drawBorder: false, display: false },
    }
}

const chartData = computed(() => ({
    labels: dates.value.length > 1 ? dates.value : ['', dates.value[0]],
    datasets: [
        getChartData(amounts.value, true, '#FA8200', 0.1),
        getChartData(previousAmounts.value, false, '#59606D', 0),
    ],
}))

function getChartData(data, fill, borderColor, fillOpacity) {
    return {
        pointBorderWidth: 0,
        pointRadius: 0,
        label: '',
        borderColor,
        borderWidth: 1,
        data: data.length > 1 ? data : [data[0], data[0]],
        tension: 0.4,
        fill,
        backgroundColor: `rgba(${
            borderColor === '#FA8200' ? '250, 130, 0' : '89, 96, 109'
        }, ${fillOpacity})`,
        borderDash: fill ? undefined : [5, 5],
    }
}

const tooltipHandler = (context) => {
    const { tooltip } = context
    const dataIndex = tooltip.dataPoints[0].dataIndex

    const currentLabel = new Date(dates.value[dataIndex])
    const previousLabel = new Date(dates.value[dataIndex])
    previousLabel.setFullYear(previousLabel.getFullYear() - 1)

    const currentAmount = !isZero.value ? amounts.value[dataIndex] ?? 0 : 0
    const previousAmount = !isZero.value
        ? previousAmounts.value[dataIndex] ?? 0
        : 0

    const dateFormat = step.value === '1 month' ? 'MMM YYYY' : 'DD MMM YYYY'

    const innerHtml = `
            <thead></thead>
            <tbody>
                <tr style="padding-bottom: 5px; font-weight: 600;" class="flex justify-between items-start w-full">
                    <td>${customDateFormat(currentLabel, dateFormat)}</td>
                    <td>${numberFormat(currentAmount)}</td>
                </tr>
                <tr class="flex justify-between items-start w-full">
                    <td>${customDateFormat(previousLabel, dateFormat)}</td>
                    <td>${numberFormat(previousAmount)}</td>
                </tr>
            </tbody>
        `

    externalTooltipHandler(context, innerHtml)
}

const chartPlugins = computed(() => {
    return [
        {
            id: 'drawVerticalLineWhenHover',
            afterDraw: (chart) => {
                drawVerticalLineWhenHover(chart, isZero.value)
            },
        },
    ]
})

const chartOptionsBase = {
    animation: false,
    plugins: {
        legend: { display: false },
        tooltip: {
            enabled: false,
            position: 'nearest',
            external: tooltipHandler,
        },
    },
    interaction: { mode: 'index', intersect: false },
    responsive: true,
    maintainAspectRatio: false,
}
</script>
