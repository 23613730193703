<template>
    <div
        class="relative flex w-full flex-1 flex-col items-start justify-between gap-y-6 self-stretch"
    >
        <business-snapshot-loading
            :loading="loading"
            type="net-profit-margin"
        />

        <business-snapshot-data-overview
            v-if="fromDate && toDate"
            :title="$t('businessSnapshot.efficiency.netProfitMargin.title')"
            :from-date="fromDate"
            :to-date="toDate"
            :current-value="current"
            :previous-value="previous"
            :percentage="percentage"
            :is-percentage="true"
            variant="black"
        />

        <div class="relative h-full w-full">
            <line-chart
                class="w-full flex-1"
                ref="line"
                :chart-data="chartData"
                :chart-options="chartOptions"
                :plugins="chartPlugins"
            />
        </div>

        <div class="text-xs font-normal text-gray-700">
            <p>
                {{ chartDescription }}
            </p>
        </div>
    </div>
</template>
<script setup>
import { CURRENCIES } from '@tenant/utils/constants'
import { useBusinessSnapshot } from '@tenant/modules/tenant/business-snapshot/composables/use-business-snapshot'
import dayjs from 'dayjs'
import { customDateFormat, shortServerFormat } from '@tenant/utils/day'
import { numberFormat } from '@tenant/utils/helper'
import { externalTooltipHandler } from '@tenant/components/chart/plugins/tooltip'

const { executeSnapshot, drawVerticalLineWhenHover, loading } =
    useBusinessSnapshot()

const { t } = useI18n()

const props = defineProps({
    fromDate: {
        type: String,
        required: true,
    },

    toDate: {
        type: String,
        required: true,
    },
})

const netProfits = ref([])

const saleRevenues = ref([])

const current = ref(0)
const previous = ref(0)
const percentage = ref(0)
const originalData = ref([])

const currentAmounts = ref([])
const previousAmounts = ref([])

const step = ref('')

const labels = ref([])

const isBothZero = computed(() => {
    return (
        currentAmounts.value.every((amount) => amount === 0) &&
        previousAmounts.value.every((amount) => amount === 0)
    )
})

onMounted(async () => {
    await fetchChartData()
})

const fetchChartData = async () => {
    const data = await executeSnapshot(
        'net-profit-margin',
        props.fromDate,
        props.toDate
    )

    if (!data) return

    current.value = data.current
    previous.value = data.previous
    percentage.value = data.percentage_change
    labels.value = data.labels
    originalData.value = data.data
    currentAmounts.value = originalData.value[0]
    previousAmounts.value = originalData.value[1]

    netProfits.value = data?.additional?.net_profits
    saleRevenues.value = data?.additional?.revenues
    step.value = data?.additional?.step
}

const chartPlugins = computed(() => {
    return [
        {
            id: 'drawVerticalLineWhenHover',
            afterDraw: (chart) => {
                drawVerticalLineWhenHover(chart, isBothZero.value)
            },
        },
    ]
})

const chartData = computed(() => {
    return {
        labels: labels.value,
        datasets: [
            {
                label: '',
                data: currentAmounts.value,
                borderColor: '#7F56D9',
                borderWidth: 2,
                pointBorderWidth: 0,
                pointBackgroundColor: 'transparent',
                pointHoverBackgroundColor: '#7F56D9',
                pointHoverBorderColor: '#7F56D9',
            },
            {
                pointBorderWidth: 0,
                pointRadius: 0,
                label: '',
                borderColor: '#59606D',
                borderWidth: 1,
                data: previousAmounts.value,
                borderDash: [5, 5],
            },
        ],
    }
})

const chartOptions = computed(() => ({
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            enabled: false,
            position: 'nearest',
            external: tooltipHandler,
        },
        chartAreaBorder: {
            display: false,
        },
    },
    interaction: {
        mode: 'index',
        intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'day',
                round: 'day',
            },
            display: true,
            offset: false,
            ticks: {
                source: 'data',
                autoSkip: false,
                color: '#667085',
                callback: function (value, index) {
                    const val = labels.value[index]
                    // if isSameMonth display first day, last day and middle day
                    if (isSameMonth.value) {
                        if (index === 0) {
                            return dayjs(val).format('DD MMM')
                        }
                        if (index === labels.value.length - 1) {
                            return dayjs(val).format('DD MMM')
                        }
                        if (index === Math.floor(labels.value.length / 2)) {
                            return dayjs(val).format('DD MMM')
                        }
                        return null
                    }

                    if (step.value === '1 month') {
                        if (labels.value.length < 12) {
                            return dayjs(val).format('MMM')
                        }

                        return index % 2 === 0 ? dayjs(val).format('MMM') : ''
                    }

                    return ''
                },
            },
            grid: {
                drawBorder: false,
                display: false,
            },
            border: {
                display: false,
            },
        },
        y: {
            display: true,
            beginAtZero: true,
            max: ({ chart }) => {
                let max = isBothZero.value ? 10 : 0

                chart.data.datasets.forEach((dataset) => {
                    const datasetMax = Math.max(...dataset.data)

                    max = datasetMax > max ? datasetMax : max
                })

                return Math.max(chart.options.scales.y.suggestedMax, max)
            },
            ticks: {
                count: 3,
                display: true,
                padding: 10,
                callback: function (value) {
                    return value.toFixed(2) + '%'
                },
            },
            border: {
                display: false,
            },
            grid: {
                drawBorder: false,
                color: '#F2F4F7',
            },
        },
    },
}))

const tooltipHandler = (context) => {
    const { tooltip } = context
    const dataIndex = tooltip.dataPoints[0].dataIndex
    const datasetIndex = tooltip.dataPoints[0].datasetIndex

    const label = new Date(labels.value[dataIndex])
    label.setFullYear(label.getFullYear() - datasetIndex)

    const dateIndex = shortServerFormat(label, 'YYYY-MM-DD')
    const netProfit = netProfits.value[datasetIndex][dateIndex] ?? 0
    const saleRevenue = saleRevenues.value[datasetIndex][dateIndex] ?? 0
    const netProfitMargin = originalData.value[datasetIndex][dataIndex] ?? 0
    const dateFormat = step.value === '1 month' ? 'MMM YYYY' : 'DD MMMM YYYY'

    const innerHTML = `
            <thead></thead>
            <tbody>
                <tr style="padding-bottom: 5px;" class="flex justify-between items-start w-full">
                    <td>${t(
                        'businessSnapshot.efficiency.netProfitMargin.tooltip.netProfit'
                    )}</td>
                    <td>${numberFormat(netProfit)}</td>
                </tr>
                <tr style="padding-bottom: 5px;" class="flex justify-between items-start w-full">
                   <td>${t(
                       'businessSnapshot.efficiency.netProfitMargin.tooltip.saleRevenue'
                   )}</td>
                    <td>${numberFormat(saleRevenue)}</td>
                </tr>
                <tr style="border-top: 1px solid #D0D5DD; padding-top: 5px;" class="flex justify-between items-start w-full"></tr>
                <tr class="flex justify-between items-start w-full">
                    <td>${customDateFormat(label, dateFormat)}</td>
                    <td>${netProfitMargin}%</td>
                </tr>
            </tbody>
        `

    externalTooltipHandler(context, innerHTML)
}

const chartDescription = computed(() => {
    if (current.value >= 0) {
        return t(
            'businessSnapshot.efficiency.netProfitMargin.description.increased',
            {
                netProfit: (current.value / 100).toFixed(2),
                symbol: CURRENCIES.eur.symbol,
            }
        )
    }

    return t(
        'businessSnapshot.efficiency.netProfitMargin.description.decreased',
        {
            netProfit: (Math.abs(current.value) / 100).toFixed(2),
            symbol: CURRENCIES.eur.symbol,
        }
    )
})

const isSameMonth = computed(() => {
    return dayjs(props.fromDate).isSame(dayjs(props.toDate), 'month')
})

watch(props, async () => {
    await fetchChartData()
})
</script>
