<template>
    <div
        class="relative flex h-[356px] w-full flex-col items-start justify-between gap-y-6 rounded-md border border-gray-300 px-6 py-8"
    >
        <business-snapshot-loading :loading="loading" type="financial-ratios" />

        <business-snapshot-data-overview
            v-if="fromDate && toDate"
            :title="$t(`businessSnapshot.financialRatios.${type}.title`)"
            :from-date="fromDate"
            :to-date="toDate"
            :current-value="current"
            :previous-value="previous"
            :percentage="percentage"
            :placeholder-for-empty-data="
                $t(`businessSnapshot.financialRatios.${type}.emptyData`)
            "
            variant="black"
        />

        <div class="relative w-full">
            <line-chart
                class="w-full flex-1"
                ref="line"
                :chart-data="chartData"
                :chart-options="chartOptions"
                :plugins="chartPlugins"
                :height="140"
            />
        </div>

        <div class="text-xs font-normal text-gray-700">
            <p>
                {{ chartDescription }}
            </p>
        </div>
    </div>
</template>

<script setup>
import { CURRENCIES } from '@tenant/utils/constants'
import { useBusinessSnapshot } from '@tenant/modules/tenant/business-snapshot/composables/use-business-snapshot'
import dayjs from 'dayjs'
import { customDateFormat } from '@tenant/utils/day'
import { externalTooltipHandler } from '@tenant/components/chart/plugins/tooltip'

const { drawVerticalLineWhenHover } = useBusinessSnapshot()

const { t } = useI18n()

const props = defineProps({
    fromDate: {
        type: String,
        required: true,
    },

    toDate: {
        type: String,
        required: true,
    },

    type: {
        type: String,
        required: true,
        validate: (value) => {
            return [
                'current',
                'debt',
                'currentLiabilitiesToNetWorth',
                'debitToEquity',
                'workingCapitalToTotalAssets',
                'fixedAssetsToNetWorth',
            ].includes(value)
        },
    },

    data: {
        type: Object,
        required: true,
        default: () => ({
            current: 0,
            previous: 0,
            percentage_change: 0,
            labels: [],
            data: [],
            additional: {},
        }),
    },

    loading: {
        type: Boolean,
        default: false,
    },

    step: {
        type: String,
        default: '',
    },
})

const ratios = ref([])

const tempData = ref([])

const current = ref(0)
const previous = ref(0)
const percentage = ref(0)

const labels = ref([])

const isBothZero = computed(() => {
    return ratios.value.every((amount) => amount === 0)
})

onMounted(async () => {
    fetchChartData()
})

const fetchChartData = () => {
    current.value = props.data.current
    previous.value = props.data.previous
    percentage.value = props.data.percentage_change
    labels.value = props.data.labels
    ratios.value = props.data.data
    tempData.value = Array(ratios.value.length).fill(0)
}

const chartPlugins = computed(() => {
    return [
        {
            id: 'drawVerticalLineWhenHover',
            afterDraw: (chart) => {
                drawVerticalLineWhenHover(chart, isBothZero.value)
            },
        },
    ]
})

const chartData = computed(() => {
    return {
        labels: labels.value,
        datasets: [
            {
                label: '',
                data: ratios.value,
                borderColor: '#0078C8',
                borderWidth: 2,
                pointBorderWidth: 0,
                pointBackgroundColor: 'transparent',
                pointHoverBackgroundColor: '#0078C8',
                pointHoverBorderColor: '#0078C8',
            },
            {
                pointBorderWidth: 0,
                pointRadius: 0,
                label: '',
                borderColor: '#59606D',
                borderWidth: 2,
                data: tempData.value,
                borderDash: [5, 5],
            },
        ],
    }
})

const chartOptions = computed(() => ({
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            enabled: false,
            position: 'nearest',
            external: tooltipHandler,
        },
        chartAreaBorder: {
            display: false,
        },
    },
    interaction: {
        mode: 'index',
        intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'day',
                round: 'day',
            },
            display: true,
            offset: false,
            ticks: {
                source: 'data',
                autoSkip: false,
                color: '#667085',
                callback: function (value, index) {
                    const val = labels.value[index]
                    // if isSameMonth display first day, last day and middle day
                    if (isSameMonth.value) {
                        if (index === 0) {
                            return dayjs(val).format('DD MMM')
                        }
                        if (index === labels.value.length - 1) {
                            return dayjs(val).format('DD MMM')
                        }
                        if (index === Math.floor(labels.value.length / 2)) {
                            return dayjs(val).format('DD MMM')
                        }
                        return null
                    }

                    if (props.step === '1 month') {
                        if (labels.value.length < 12) {
                            return dayjs(val).format('MMM')
                        }

                        return index % 2 === 0 ? dayjs(val).format('MMM') : ''
                    }

                    return ''
                },
            },
            grid: {
                drawBorder: false,
                display: false,
            },
            border: {
                display: false,
            },
        },
        y: {
            display: true,
            beginAtZero: true,
            max: ({ chart }) => {
                let max = isBothZero.value ? 10 : 0

                chart.data.datasets.forEach((dataset) => {
                    const datasetMax = Math.max(...dataset.data)

                    max = datasetMax > max ? datasetMax : max
                })

                return Math.max(chart.options.scales.y.suggestedMax, max)
            },
            ticks: {
                count: 3,
                display: true,
                padding: 10,
                callback: function (value) {
                    return value.toFixed(1)
                },
            },
            border: {
                display: false,
            },
            grid: {
                drawBorder: false,
                color: '#CCC',
            },
        },
    },
}))

const tooltipHandler = (context) => {
    const { tooltip } = context
    const dataIndex = tooltip.dataPoints[0].dataIndex

    const label = new Date(labels.value[dataIndex])

    const ratio = ratios.value[dataIndex] ?? 0
    const dateFormat = props.step === '1 month' ? 'MMM YYYY' : 'DD MMMM YYYY'

    const innerHTML = `
            <thead></thead>
            <tbody>
                <tr class="flex justify-between items-start w-full">
                    <td>${customDateFormat(label, dateFormat)}</td>
                    <td>${ratio}</td>
                </tr>
            </tbody>
        `

    externalTooltipHandler(context, innerHTML)
}

const chartDescription = computed(() => {
    if (current.value) {
        return t(
            `businessSnapshot.financialRatios.${props.type}.description.hasRatio`,
            {
                value: current.value.toFixed(2),
                symbol: CURRENCIES.eur.symbol,
            }
        )
    }

    return t(
        `businessSnapshot.financialRatios.${props.type}.description.noRatio`
    )
})

const isSameMonth = computed(() => {
    return dayjs(props.fromDate).isSame(dayjs(props.toDate), 'month')
})

watch(
    props,
    () => {
        fetchChartData()
    },
    { deep: true, immediate: true }
)
</script>
