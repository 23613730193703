<template>
    <div
        class="relative flex w-full flex-col items-start rounded-md border border-gray-300 px-6 py-8"
    >
        <business-snapshot-loading :loading="loading" type="overall-cash" />

        <div class="mb-8 flex flex-col gap-1 text-gray-900">
            <h6 class="text-sm font-semibold">
                {{ $t('businessSnapshot.financialCash.overallCashBalance') }}
            </h6>
            <span class="text-xl font-semibold">
                {{ $filters.numberFormat(totalOverallCash) }}
            </span>

            <div class="flex flex-col gap-1 py-3.5 text-xs">
                <div
                    class="flex flex-col"
                    v-for="item in items.slice(0, 3)"
                    :key="item.id"
                >
                    <p class="font-normal">
                        {{ item.name }}
                    </p>
                    <span class="font-semibold">
                        {{ $filters.numberFormat(item.closing_balance) }}
                    </span>
                </div>
            </div>

            <p class="text-xs font-normal" v-if="items.length > 0">
                {{
                    $t(
                        'businessSnapshot.financialCash.basedOnAccounts',
                        items.length
                    )
                }}
            </p>
        </div>

        <span
            class="flex cursor-pointer items-center justify-center gap-2 text-sm font-medium text-primary-700"
        >
            {{ $t('businessSnapshot.financialCash.seeUpcomingCashFlow') }}

            <base-icon
                name="line-icons:general:link-external-02"
                variant="inherit"
            />
        </span>
    </div>
</template>

<script setup>
import { useBusinessSnapshot } from '@tenant/modules/tenant/business-snapshot/composables/use-business-snapshot'

const props = defineProps({
    fromDate: {
        type: String,
        required: true,
    },

    toDate: {
        type: String,
        required: true,
    },
})

const { executeSnapshot, result, loading } = useBusinessSnapshot()

const items = computed(() => result.value || [])

const totalOverallCash = computed(() => {
    return items.value.reduce((cur, item) => cur + item.closing_balance, 0.0)
})

onMounted(() => {
    fetchSnapshot()
})
watch(props, () => {
    fetchSnapshot()
})

const fetchSnapshot = () => {
    return executeSnapshot('overall-cash', props.fromDate, props.toDate)
}
</script>
