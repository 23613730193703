<template>
    <div
        class="relative flex w-full flex-col items-start rounded-md border border-gray-300 px-6 py-8"
    >
        <business-snapshot-loading :loading="loading" type="balance-sheet" />

        <div class="mb-10 flex min-w-44 flex-col gap-1 text-gray-900">
            <h6 class="text-sm font-semibold">
                {{ $t('businessSnapshot.financialCash.balanceSheet') }}
            </h6>
            <span class="text-xs font-normal">
                {{ $t('businessSnapshot.financialCash.assets') }}:
                <b class="font-semibold">
                    {{ $filters.numberFormat(assets) }}
                </b>
            </span>
            <hr class="border-gray-300" />
            <span class="text-xs font-normal">
                {{ $t('businessSnapshot.financialCash.liabilities') }}:
                <b class="font-semibold">
                    {{ $filters.numberFormat(liabilities) }}
                </b>
            </span>
            <span class="text-xs font-normal">
                {{ $t('businessSnapshot.financialCash.equity') }}:
                <b class="font-semibold">
                    {{ $filters.numberFormat(equity) }}
                </b>
            </span>
        </div>

        <div
            class="mb-8 ml-3 flex w-full gap-2.5"
            v-if="
                equity >= 0 && liabilities >= 0 && totalLiabilitiesAndEquity > 0
            "
        >
            <div
                class="flex h-28 w-28 items-center justify-center rounded-md border border-gray-500 bg-gray-200"
            >
                <span class="text-sm font-normal text-gray-900">
                    {{ $t('businessSnapshot.financialCash.assets') }}
                </span>
            </div>
            <div class="relative flex h-28 w-28">
                <div class="absolute -top-1 left-full">
                    <div
                        class="absolute -left-3.5 top-1/2 z-10 mt-0.5 flex -translate-y-1/2 items-center justify-center bg-gray-500 px-0.5"
                    >
                        <div class="h-px w-4" />
                    </div>
                    <span class="ml-2.5 text-xs font-normal text-gray-700">
                        {{ $t('businessSnapshot.financialCash.liabilities') }}
                    </span>
                </div>

                <div class="absolute -bottom-1 left-full">
                    <div
                        class="absolute -left-3.5 top-1/2 z-10 mt-0.5 flex -translate-y-1/2 items-center justify-center bg-gray-500 px-0.5"
                    >
                        <div class="h-px w-4" />
                    </div>
                    <span class="ml-2.5 text-xs font-normal text-gray-700">
                        {{ $t('businessSnapshot.financialCash.equity') }}
                    </span>
                </div>

                <div
                    class="absolute inset-0 overflow-hidden rounded-md border border-blue-light-500"
                >
                    <div
                        class="absolute bottom-0 left-0 right-0 border-t border-blue-light-500 bg-blue-light-100"
                        :style="{
                            top: `${percentBalance(liabilities)}%`,
                        }"
                    ></div>
                    <div
                        class="absolute left-0 right-0 top-0 bg-blue-light-200"
                        :style="{ bottom: `${percentBalance(equity)}%` }"
                    ></div>
                </div>
            </div>
        </div>

        <p class="text-xs font-normal text-gray-700">
            {{ $t('businessSnapshot.financialCash.assetsEqualToEquity') }}
        </p>
    </div>
</template>

<script setup>
import { useBusinessSnapshot } from '@tenant/modules/tenant/business-snapshot/composables/use-business-snapshot'

const props = defineProps({
    fromDate: {
        type: String,
        required: true,
    },

    toDate: {
        type: String,
        required: true,
    },
})

const { executeSnapshot, result, loading } = useBusinessSnapshot()

const assets = computed(() => {
    if (!result.value) {
        return 0
    }

    return result.value.assets
})

const liabilities = computed(() => {
    if (!result.value) {
        return 0
    }

    return result.value.liabilities
})

const equity = computed(() => {
    if (!result.value) {
        return 0
    }

    return result.value.equity
})

const totalLiabilitiesAndEquity = computed(() => {
    return liabilities.value + equity.value
})

onMounted(() => {
    fetchSnapshot()
})

watch(props, () => {
    fetchSnapshot()
})

const fetchSnapshot = () => {
    return executeSnapshot('balance-sheet', props.fromDate, props.toDate)
}

const percentBalance = (value) => {
    if (totalLiabilitiesAndEquity.value > 0) {
        return 0
    }

    return (value * 100) / totalLiabilitiesAndEquity.value
}
</script>
