import { dateCustomFormat } from '@tenant/core/filter'

export const useBusinessSnapshot = () => {
    const { execute, result, replaceEndpoint, loading } = useApi(
        '/api/business-snapshot/{type}',
        'GET'
    )

    const _removeLastZero = (result) => {
        if (result.endsWith('.00')) {
            result = result.substring(0, result.length - 3)
        } else if (result.endsWith('0')) {
            result = result.substring(0, result.length - 1)
        }

        return result
    }
    const _getSameDateOfPreviousYear = (date) => {
        if (date.getMonth() === 1) {
            const lastYear = date.getFullYear() - 1
            if (
                (lastYear % 4 === 0 && lastYear % 100 !== 0) ||
                lastYear % 400 === 0
            ) {
                return new Date(lastYear, 1, 29)
            } else {
                return new Date(lastYear, 1, 28)
            }
        }

        return new Date(date.getFullYear() - 1, date.getMonth(), date.getDate())
    }

    /**
     *
     * @param from
     * @param to
     */
    const getTextPreviousPeriod = (from, to) => {
        const fromDate = new Date(from)
        const toDate = new Date(to)
        const previousFromDate = _getSameDateOfPreviousYear(fromDate)

        const previousToDate = _getSameDateOfPreviousYear(toDate)

        if (previousFromDate.getMonth() === previousToDate.getMonth()) {
            return dateCustomFormat(previousFromDate, 'MMM YYYY')
        }

        return `${dateCustomFormat(
            previousFromDate,
            'MMM'
        )} - ${dateCustomFormat(previousToDate, 'MMM YYYY')}`
    }

    /**
     *
     * @param from
     * @param to
     */
    const getTextCurrentPeriod = (from, to) => {
        if (new Date(from).getMonth() === new Date(to).getMonth()) {
            return dateCustomFormat(new Date(from), 'MMM YYYY')
        }

        return `${dateCustomFormat(new Date(from), 'MMM')} - ${dateCustomFormat(
            new Date(to),
            'MMM YYYY'
        )}`
    }

    /**
     *
     * @param {string} type
     * @param {string} fromDate
     * @param {string} toDate
     *
     * @returns {Promise<void>}
     */
    const executeSnapshot = async (type, fromDate, toDate) => {
        replaceEndpoint(`/api/business-snapshot/${type}`)

        await execute({
            queries: {
                from_date: fromDate,
                to_date: toDate,
            },
        })

        return result.value
    }

    const convertToKMB = (value) => {
        let result = value ?? 0
        if (Math.abs(value) >= 1000000000) {
            result = _removeLastZero((value / 1000000000).toFixed(2)) + 'B'
        } else if (Math.abs(value) >= 1000000) {
            result = _removeLastZero((value / 1000000).toFixed(2)) + 'M'
        } else if (Math.abs(value) >= 1000) {
            result = _removeLastZero((value / 1000).toFixed(2)) + 'K'
        }

        if (typeof result !== 'string') {
            return _removeLastZero(result.toFixed(2))
        }

        return result
    }

    const drawVerticalLineWhenHover = (chart, isZero) => {
        let x = null
        if (!isZero && chart.tooltip?._active?.length) {
            x = chart.tooltip._active[0].element.x
        }

        if (isZero) {
            const xAxis = chart.scales.x
            x =
                (xAxis.getPixelForValue(xAxis.min) +
                    xAxis.getPixelForValue(xAxis.max)) /
                2
        }

        if (x === null) {
            return
        }

        const yAxis = chart.scales.y
        const ctx = chart.ctx
        ctx.save()
        ctx.beginPath()
        ctx.moveTo(x, yAxis.top)
        ctx.lineTo(x, yAxis.bottom)
        ctx.lineWidth = 1
        ctx.strokeStyle = '#475467'
        ctx.stroke()
        ctx.restore()
    }

    return {
        result,
        loading,
        executeSnapshot,
        getTextPreviousPeriod,
        getTextCurrentPeriod,
        convertToKMB,
        drawVerticalLineWhenHover,
    }
}
