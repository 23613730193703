import { GUARD } from '@tenant/utils/constants'

const routes = [
    {
        name: 'business-snapshot',
        path: '/business-snapshot',
        redirect: {
            name: 'business-snapshot.index',
        },
        children: [
            {
                name: 'business-snapshot.index',
                path: 'dashboard',
                component: () => import('./pages/business-snapshot-page.vue'),
            },
        ],
    },
]

export default {
    routes,
    meta: {
        layout: 'dashboard-layout',
        guard: GUARD.TENANT,
        permission: 'view_reports',
    },
}
