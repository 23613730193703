export default {
    businessSnapshot: {
        title: 'Business Snapshot',
        description: {
            lastMonth: 'For the month ended {date}',
            lastQuarter: 'For the quarter from {startDate} - {endDate}',
            lastFinancialYear:
                'For the financial year from {startDate} - {endDate}',
            yearToDate: 'Year to date from {startDate} - {endDate}',
        },
        range: {
            lastMonth: 'Last Month',
            lastQuarter: 'Last Quarter',
            lastFinancialYear: 'Last Financial Year',
            yearToDate: 'Year to Date',
        },
        general: {
            comparedToLastYear: '{percentage}% from {range}',
            noData: 'No data from previous period',
        },
        profitability: {
            title: 'Profitability',
            profitAndLoss: {
                title: 'Profit & Loss',
                comparedToLastYear: '{percentage}% from {range}',
                noData: 'No data from previous period',
                madeProfit:
                    'Profit is the amount of money made after paying expenses',
                noProfit:
                    'No profit was made because the expenses were higher than the income',
            },
            income: {
                title: 'Income',
            },
            expenses: {
                title: 'Expenses',
                costOfGoodsSold: 'Cost of Goods Sold',
                operatingExpenses: 'Operating Expenses',
            },
        },
        efficiency: {
            title: 'Efficiency',
            netProfitMargin: {
                title: 'Net Profit Margin',
                tooltip: {
                    netProfit: 'Net Profit',
                    saleRevenue: '÷ Sale Revenue',
                },
                description: {
                    increased:
                        'For every 1{symbol} of sales revenue, an average net profit of {netProfit}{symbol} is made.',
                    decreased:
                        'For every 1{symbol} of sales revenue, an average net loss of {netProfit}{symbol} is made.',
                },
            },
            grossProfitMargin: {
                title: 'Gross Profit Margin',
                labels: {
                    cogs: 'Cost of Goods Sold',
                    salesRevenue: 'Sales Revenue',
                    grossProfit: 'Gross Profit',
                },
                description: {
                    increased:
                        'For every 1{symbol} of sales revenue, an average gross profit of {netProfit}{symbol} is made.',
                    decreased:
                        'For every 1{symbol} of sales revenue, an average gross loss of {netProfit}{symbol} is made.',
                },
            },
            largestOperatingExpense: {
                title: 'Largest operating expenses',
            },
        },
        financialCash: {
            title: 'Financial position and cash',
            onDate: 'on {date}',
            balanceSheet: 'Balance Sheet',
            assetsEqualToEquity: 'Assets are equal to liabilities plus equity',
            assets: 'Assets',
            liabilities: 'Liabilities',
            equity: 'Equity',
            overallCashBalance: 'Overall cash balance',
            basedOnAccounts: 'Based on {count} accounts. See all accounts.',
            seeUpcomingCashFlow: 'See your upcoming cash flow',
            averagePaid: 'Average time to get paid',
            averagePay: 'Average time to pay suppliers',
            valueOfInvoices: 'Value of unpaid invoices',
            valueOfBills: 'Value of unpaid bills',
        },
        financialRatios: {
            title: 'Financial Ratios',
            current: {
                title: 'Current Ratio',
                description: {
                    hasRatio:
                        'For every 1{symbol} in current liabilities, you have {value}{symbol} of current assets.',
                    noRatio:
                        'This ratio couldn’t be calculated as there are no current liabilities for the selected period',
                },
                emptyData: 'N/A (no current liabilities)',
            },
            debt: {
                title: 'Debt Ratio',
                description: {
                    hasRatio:
                        'For every 1{symbol} of assets, you have {value}{symbol} of debt.',
                    noRatio:
                        'This ratio couldn’t be calculated as there are no asset for the selected period',
                },
                emptyData: 'N/A (no assets)',
            },
            currentLiabilitiesToNetWorth: {
                title: 'Current liabilities to net worth',
                description: {
                    hasRatio:
                        'For every 1{symbol} of equity, your business is financing {value}{symbol} in short-term debts.',
                    noRatio:
                        'This ratio couldn’t be calculated as there are no current liabilities for the selected period',
                },
                emptyData: 'N/A (no current liabilities)',
            },
            debitToEquity: {
                title: 'Debt to equity ratio',
                description: {
                    hasRatio:
                        'For every 1{symbol} of equity, your business has {value}{symbol} of debt.',
                    noRatio:
                        'This ratio couldn’t be calculated as there are no equity for the selected period',
                },
                emptyData: 'N/A (no equities)',
            },
            workingCapitalToTotalAssets: {
                title: 'Working capital to total assets',
                description: {
                    hasRatio:
                        'For every 1{symbol} of total assets, a business has {value}{symbol} of these are working capital.',
                    noRatio:
                        'This ratio couldn’t be calculated as there are no asset for the selected period',
                },
                emptyData: 'N/A (no assets)',
            },
            fixedAssetsToNetWorth: {
                title: 'Fixed assets to net worth',
                description: {
                    hasRatio:
                        'For every 1{symbol} of equity, your business has {value}{symbol} tied up in fixed assets.',
                    noRatio:
                        'This ratio couldn’t be calculated as there are no assets for the selected period',
                },
                emptyData: 'N/A (no assets)',
            },
        },
    },
}
