<template>
    <div class="grid h-full w-full grid-rows-2 gap-6">
        <div
            class="relative flex flex-col items-start rounded-md border border-gray-300 px-6 py-8 text-gray-900"
        >
            <business-snapshot-loading :loading="loading" type="average" />

            <h6 class="text-sm font-semibold">
                {{ $t('businessSnapshot.financialCash.averagePaid') }}
            </h6>
            <span class="text-xl font-semibold">
                {{
                    $t('general.days', {
                        number: paid?.average_time
                            ? $filters.numberFormat(paid?.average_time)
                            : 0,
                    })
                }}
            </span>
            <span class="text-xl font-semibold">-</span>
            <span class="text-xs font-normal">
                {{ $t('businessSnapshot.financialCash.valueOfInvoices') }}:
                <span class="font-semibold">
                    {{ paid?.amount ? $filters.numberFormat(paid?.amount) : 0 }}
                </span>
            </span>
        </div>

        <div
            class="relative flex flex-col items-start rounded-md border border-gray-300 px-6 py-8 text-gray-900"
        >
            <business-snapshot-loading :loading="loading" type="average" />

            <h6 class="text-sm font-semibold">
                {{ $t('businessSnapshot.financialCash.averagePay') }}
            </h6>
            <span class="text-xl font-semibold">
                {{
                    $t('general.days', {
                        number: pay?.average_time
                            ? $filters.numberFormat(pay?.average_time, 2)
                            : 0,
                    })
                }}
            </span>
            <span class="text-xl font-semibold">-</span>
            <span class="text-xs font-normal">
                {{ $t('businessSnapshot.financialCash.valueOfBills') }}:
                <span class="font-semibold">
                    {{
                        pay?.amount ? $filters.numberFormat(pay?.amount, 2) : 0
                    }}
                </span>
            </span>
        </div>
    </div>
</template>

<script setup>
import { useBusinessSnapshot } from '@tenant/modules/tenant/business-snapshot/composables/use-business-snapshot'

const props = defineProps({
    fromDate: {
        type: String,
        required: true,
    },

    toDate: {
        type: String,
        required: true,
    },
})

const { executeSnapshot, result, loading } = useBusinessSnapshot()

const paid = computed(() => result.value?.paid)
const pay = computed(() => result.value?.pay)

onMounted(() => {
    fetchSnapshot()
})

watch(props, () => {
    fetchSnapshot()
})

const fetchSnapshot = () => {
    return executeSnapshot('average', props.fromDate, props.toDate)
}
</script>
