<template>
    <div
        v-if="loading"
        class="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50"
    >
        <span
            class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
        >
            <base-loading size="lg" />
        </span>
    </div>
</template>

<script setup>
const emitter = useEmitter()

const props = defineProps({
    loading: {
        type: Boolean,
        required: true,
        default: false,
    },
    type: {
        type: String,
        required: true,
    },
})

watch(
    () => props.loading,
    (value) => {
        if (!value) {
            emitter.emit('bs-loaded', props.type)
        }
    }
)
</script>
