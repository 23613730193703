const navigation = [
    {
        id: 'sas-business-snapshot',
        path: 'business-snapshot',
        label: 'sidebar.businessSnapshot.label',
        parent: 'sas-reports',
        position: 1,
        permission: 'view_reports',
    },
]

export default navigation
