<template>
    <div class="flex flex-col items-start gap-y-[5px]">
        <span class="text-sm font-semibold text-gray-900">
            {{ title }}
        </span>
        <span class="text-xl font-semibold" :class="textStyles">
            {{ displayCurrentValue }}
        </span>
        <div class="flex items-center gap-x-2.5">
            <div
                v-if="previousValue"
                class="flex flex-row items-center justify-center gap-1 rounded-2xl p-1"
                :class="{
                    'bg-success-50': percentage >= 0,
                    'bg-danger-50': percentage < 0,
                }"
            >
                <base-icon
                    v-if="percentage >= 0"
                    name="line-icons:arrows:arrow-up"
                    size="sm"
                    variant="success"
                />
                <base-icon
                    v-else
                    name="line-icons:arrows:arrow-down"
                    size="sm"
                    variant="danger"
                />
            </div>

            <span class="text-sm font-normal text-gray-900">
                {{ currentValueDescription }}
            </span>
        </div>
    </div>
</template>

<script setup>
import { useBusinessSnapshot } from '@tenant/modules/tenant/business-snapshot/composables/use-business-snapshot'
import { numberFormat } from '@tenant/utils/helper'

const { getTextPreviousPeriod } = useBusinessSnapshot()
const { t } = useI18n()

const props = defineProps({
    title: {
        type: String,
        required: true,
    },

    fromDate: {
        type: String,
        required: true,
    },

    toDate: {
        type: String,
        required: true,
    },

    previousValue: {
        type: Number,
        required: false,
        default: null,
    },

    percentage: {
        type: Number,
        required: false,
        default: null,
    },

    currentValue: {
        type: Number,
        required: false,
        default: 0,
    },

    variant: {
        type: String,
        required: true,
        validator: (value) => {
            return ['primary', 'black'].includes(value)
        },
    },

    isPercentage: {
        type: Boolean,
        required: false,
        default: false,
    },

    placeholderForEmptyData: {
        type: String,
        required: false,
    },
})

const textStyles = computed(() => {
    return {
        primary: 'text-primary-600',
        black: 'text-gray-900',
    }[props.variant]
})

const previousPeriod = computed(() => {
    return getTextPreviousPeriod(props.fromDate, props.toDate)
})

const displayCurrentValue = computed(() => {
    const value = props.currentValue ?? 0
    if (!value && props.placeholderForEmptyData) {
        return props.placeholderForEmptyData
    }

    return !props.isPercentage ? numberFormat(value, 2) : value.toFixed(2) + '%'
})

const currentValueDescription = computed(() => {
    if (!props.previousValue) {
        return t('businessSnapshot.general.noData')
    }

    return t('businessSnapshot.general.comparedToLastYear', {
        percentage: Math.abs(props.percentage),
        range: previousPeriod.value,
    })
})
</script>
