<template>
    <div class="relative flex w-full flex-1 flex-col items-start self-stretch">
        <business-snapshot-loading
            :loading="loading"
            type="gross-profit-margin"
        />

        <business-snapshot-data-overview
            v-if="fromDate && toDate"
            :title="$t('businessSnapshot.efficiency.grossProfitMargin.title')"
            :from-date="fromDate"
            :to-date="toDate"
            :current-value="current"
            :previous-value="previous"
            :percentage="percentage"
            :is-percentage="true"
            variant="black"
        />

        <div
            class="w-full pt-6"
            :class="{
                '!pt-14': saleRevenueAmount,
            }"
        >
            <div
                v-if="saleRevenueAmount"
                ref="chartContainer"
                class="relative flex w-full flex-col"
            >
                <div
                    class="absolute top-[-19px]"
                    :class="{
                        'left-1/2 -translate-x-1/2': !grossProfitAmount,
                        'left-0': grossProfitAmount,
                        hidden: !cogsAmount || !saleRevenueAmount,
                    }"
                >
                    <div class="flex flex-col">
                        <p class="text-sm text-gray-900">
                            {{
                                $t(
                                    'businessSnapshot.efficiency.grossProfitMargin.labels.cogs'
                                )
                            }}
                        </p>
                        <div
                            class="vertical-line"
                            :style="cogsLabelStyles"
                        ></div>
                    </div>
                </div>

                <div
                    class="absolute right-0 top-[-19px]"
                    :class="{
                        'right-1/2 translate-x-1/2': !cogsAmount,
                        'right-0': cogsAmount,
                        hidden: !grossProfitAmount || !saleRevenueAmount,
                    }"
                >
                    <div class="flex flex-col text-right">
                        <p class="text-sm text-gray-900">
                            {{
                                $t(
                                    'businessSnapshot.efficiency.grossProfitMargin.labels.grossProfit'
                                )
                            }}
                        </p>
                        <div
                            class="vertical-line"
                            :style="grossProfitLabelStyles"
                        ></div>
                    </div>
                </div>

                <stackbar-chart
                    class="h-1/2 w-full flex-1"
                    ref="line"
                    :height="64"
                    :chart-data="chartData"
                    :chart-options="chartOptions"
                    :plugins="chartPlugins"
                />
            </div>

            <div v-else class="flex flex-col text-sm text-gray-700">
                <div>
                    <span>
                        {{
                            $t(
                                'businessSnapshot.efficiency.grossProfitMargin.labels.salesRevenue'
                            )
                        }}
                    </span>
                    <span class="font-bold"> 0 </span>
                </div>

                <div>
                    <span>
                        {{
                            $t(
                                'businessSnapshot.efficiency.grossProfitMargin.labels.cogs'
                            )
                        }}
                    </span>
                    <span class="font-bold"> 0 </span>
                </div>
                <div>
                    <span>
                        {{
                            $t(
                                'businessSnapshot.efficiency.grossProfitMargin.labels.grossProfit'
                            )
                        }}
                    </span>
                    <span class="font-bold"> 0 </span>
                </div>
            </div>

            <div
                v-if="saleRevenueAmount"
                class="h-[17px] w-full border border-t-0 border-gray-700"
            />
            <div
                v-if="saleRevenueAmount"
                class="pt-1.5 text-center text-xs text-gray-700"
            >
                <span class="font-normal">
                    {{
                        $t(
                            'businessSnapshot.efficiency.grossProfitMargin.labels.salesRevenue'
                        )
                    }}:
                </span>
                <span class="font-bold">
                    {{ $filters.numberFormat(saleRevenueAmount) }}
                </span>
            </div>

            <div
                class="pt-4 text-xs font-normal text-gray-700"
                :class="{
                    '!pt-6': !saleRevenueAmount,
                }"
            >
                <p>
                    {{ chartDescription }}
                </p>
            </div>
        </div>
    </div>
</template>
<script setup>
import { CURRENCIES } from '@tenant/utils/constants'
import { useBusinessSnapshot } from '@tenant/modules/tenant/business-snapshot/composables/use-business-snapshot'

const { executeSnapshot, loading } = useBusinessSnapshot()

const { t } = useI18n()

const props = defineProps({
    fromDate: {
        type: String,
        required: true,
    },

    toDate: {
        type: String,
        required: true,
    },
})

const current = ref(0)
const previous = ref(0)
const percentage = ref(0)
const originalData = ref([])
const chartContainer = ref(null)

const cogsAmount = ref(0)
const grossProfitAmount = ref(0)
const saleRevenueAmount = ref(0)

onMounted(async () => {
    await fetchChartData()
})

const fetchChartData = async () => {
    const data = await executeSnapshot(
        'gross-profit-margin',
        props.fromDate,
        props.toDate
    )

    if (!data) return

    current.value = data.current
    previous.value = data.previous
    percentage.value = data.percentage_change
    originalData.value = data.data
    cogsAmount.value = originalData.value[0]
    grossProfitAmount.value = originalData.value[1]
    saleRevenueAmount.value = data.additional.sale_revenues
}

const chartPlugins = computed(() => {
    return []
})

const chartData = computed(() => {
    let data = [
        {
            label: t(
                'businessSnapshot.efficiency.grossProfitMargin.labels.cogs'
            ),
            data: [cogsAmount.value],
            borderColor: '#6941C6',
            borderWidth: 1,
            borderRadius: {
                topLeft: 4,
                bottomLeft: 4,
                topRight: grossProfitAmount.value ? 0 : 4,
                bottomRight: grossProfitAmount.value ? 0 : 4,
            },
            backgroundColor: '#E9D7FE',
            borderSkipped: false,
        },
        {
            label: t(
                'businessSnapshot.efficiency.grossProfitMargin.labels.grossProfit'
            ),
            data: [grossProfitAmount.value],
            borderColor: '#6941C6',
            borderWidth: 1,
            borderRadius: {
                topRight: 4,
                bottomRight: 4,
                topLeft: cogsAmount.value ? 0 : 4,
                bottomLeft: cogsAmount.value ? 0 : 4,
            },
            backgroundColor: '#B692F6',
            borderSkipped: cogsAmount.value ? 'left' : false,
        },
    ]

    data = grossProfitAmount.value > 0 ? data : data.reverse()
    data[0] = {
        ...data[0],
        borderSkipped: false,
    }

    data[1] = {
        ...data[1],
        borderSkipped: grossProfitAmount.value ? 'left' : false,
    }

    return {
        labels: [
            t(
                'businessSnapshot.efficiency.grossProfitMargin.labels.salesRevenue'
            ),
        ],
        datasets: data,
    }
})

const chartOptions = computed(() => ({
    indexAxis: 'y',
    layout: {
        padding: {
            top: 0,
            bottom: 0,
        },
    },
    plugins: {
        legend: {
            display: false,
        },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            stacked: true,
            display: false,
            max: Math.max(
                cogsAmount.value,
                grossProfitAmount.value,
                saleRevenueAmount.value,
                0
            ),
            min: Math.min(
                cogsAmount.value,
                grossProfitAmount.value,
                saleRevenueAmount.value,
                0
            ),
        },
        y: {
            stacked: true,
            display: false,
        },
    },
}))

const chartDescription = computed(() => {
    if (current.value >= 0) {
        return t(
            'businessSnapshot.efficiency.grossProfitMargin.description.increased',
            {
                netProfit: (current.value / 100).toFixed(2),
                symbol: CURRENCIES.eur.symbol,
            }
        )
    }

    return t(
        'businessSnapshot.efficiency.grossProfitMargin.description.decreased',
        {
            netProfit: (Math.abs(current.value) / 100).toFixed(2),
            symbol: CURRENCIES.eur.symbol,
        }
    )
})

watch(props, async () => {
    await fetchChartData()
})

const cogsLabelStyles = computed(() => {
    const position = calculatePosition(cogsAmount.value)
    if (!position) {
        return {
            display: 'none',
        }
    }

    if (!grossProfitAmount.value) {
        return {
            transform: `translateX(50%)`,
        }
    }

    return {
        transform: `translateX(${position}px)`,
    }
})

const grossProfitLabelStyles = computed(() => {
    const position = calculatePosition(grossProfitAmount.value)
    if (!position) {
        return {
            display: 'none',
        }
    }

    if (!cogsAmount.value) {
        return {
            transform: `translateX(-50%)`,
        }
    }

    return {
        transform: `translateX(-${position}px)`,
    }
})

const calculatePosition = (amount) => {
    if (!amount || !saleRevenueAmount.value || !chartContainer.value) {
        return null
    }

    const chartWidth = chartContainer.value.getBoundingClientRect().width
    const percentage =
        (Math.abs(amount) * 100) /
        (Math.abs(grossProfitAmount.value) + Math.abs(cogsAmount.value))

    return Math.min(((percentage / 100) * chartWidth) / 2, 20)
}
</script>
<style lang="scss">
.vertical-line {
    position: relative;
}

.vertical-line::after {
    content: '';
    position: absolute;
    width: 0.387px;
    height: 16.5px;
    background-color: #59606d;
}
</style>
